<template>
  <div>

    <memlist-modal :visible="showModal" @close="closing">

      <DonateraWidgetContent 
        :linkstr="linkstr"
        :form="form"
      />

    </memlist-modal>

</div>
</template>

<script>
import axios from 'axios';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import DonateraWidgetContent from './DonateraWidgetContent.vue';


export default {
  name: 'DonateraWidgetPopup',
  props: ['linkstr','form'],
  mixins: [ toasts ],
  components: {
    DonateraWidgetContent
  },
  computed: {
    
  },
  data() {
    return {
      showModal: false,
      bg: 'white',

    };
  },
  async mounted() {
    
  },
  watch: {
    
  },
  beforeDestroy() {
    
  },
  methods: {

    closing() {
      this.showModal = false;
      this.$emit('closed');
    },

    show() {
      this.showModal = true;
    }
  }
};
</script>
